import { CropConstants } from 'utils/constants/Crop';

export const polandCountryConstant = { label: 'POL', value: 'POLAND', id: 'PL' };

export const HungaryCountryConstant = {
  label: 'HUN',
  value: 'HUNGARY',
  id: 'HU',
  checboxFirstText: 'Részt kívánok venni a nyereményjátékban. Megismertem és elfogadom a ',
  checboxSecondText: 'játékszabályzatot.',
};

export const GermanyCountryConstant = {
  label: 'DEU',
  value: 'GERMANY',
  id: 'DE',
  checboxFirstText: 'Ich möchte am Gewinnspiel teilnehmen und akzeptiere die ',
  checboxSecondText: 'Teilnahmebedingungen',
};

export const UkraineCountryConstant = { label: 'UKR', value: 'UKRAINE', id: 'UA' };
export const SouthAfricaCountryConstant = { label: 'ZAF', value: 'South Africa', id: 'ZA' };
export const isRomania = (country: string | undefined): boolean => {
  const { ROMANIA, ROMANIA_CODE, ROMANIA_POSTAL_CODE } = CropConstants;
  const contArr = [ROMANIA, ROMANIA_CODE, ROMANIA_POSTAL_CODE];
  return contArr?.some((cont) => cont.toLowerCase() === country?.toLowerCase());
};

export const pdfNamePerCountry = [
  {
    countryCode: 'ro',
    text: 'Syngenta_Recomandare de hibrizi pentru...',
  },
  {
    countryCode: 'pl',
    text: 'Rekomendacja od Syngenta',
  },
  {
    countryCode: 'hu',
    text: 'Syngenta vetőmag ajánlat ',
  },
];
export const TARGET_EAME_COUNTRIES = ['POL', 'ROU', 'HUN', 'DEU', 'UKR', 'BGR', 'ITA'];

export const COUNTRY_PHONE_DIAL_CODES = {
  POL: '48',
};

// cropRegion is mandatory attribute for soil-grid api from ITWORX
// since we call it before selecting a crop we are sending hardcoded values based on countryCode
export const getCountryCropRegion = (countryCode: string | undefined): string => {
  if (countryCode) {
    if (countryCode === CropConstants.BRAZIL_CODE) {
      return 'CORNLATAMSUMMER';
    } else if (countryCode === CropConstants.SOUTH_AFRICA_CODE) {
      return 'WHEATSA';
    } else if (TARGET_EAME_COUNTRIES.includes(countryCode)) {
      return 'CORNEAME';
    }
  }
  return '';
};

export const SUNFLOWER_ENABLED_COUNTRIES = ['ROU', 'HUN', 'UKR', 'BGR'];

// sms feature is toggled off for romania as requested
//TODO: Temporarily removed isSmsEnabledCountry from launch darkly featureflag
export const SMS_ENABLED_COUNTRIES = ['HUN', 'BGR', 'POL', 'ITA', 'BRZ', 'UKR', 'ZAF', 'DEU'];

export const BulgariaCountryConstant = { label: 'BGR', value: 'BULGARIA', id: 'BG' };
export const RomaniaCountryConstant = { label: 'ROU', value: 'ROMANIA', id: 'RO' };
export const BrazilCountryConstant = { label: 'BRZ', value: 'BRAZIL', id: 'BR' };

export const CONTEST_ENABLED_COUNTRIES = [''];

export const BARLEY_ENABLED_ENV = ['local', 'dev', 'qa', 'uat'];
